<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-4 mx-auto">
            <v-toolbar dark color="primary">
              <v-toolbar-title
                >{{ $t('common.login') }}</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="email"
                        :rules="rules.emailRules"
                        :label="$t('common.email')"
                        @keypress.enter="onLogin"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show1 ? 'text' : 'password'"
                        :label="$t('common.password')"
                        @click:append="show1 = !show1"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center align-center">
              <v-btn
                align-center
                justify-center
                color="primary"
                @click="onLogin"
                >{{ $t('common.login') }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import functionUtils from "Utils/functionUtils";
import { mapActions } from "vuex";
import { isCookieEnabled, setCookie, removeCookie } from "tiny-cookie";
export default {
  components: {
  },
  data() {
    return {
      valid: false,
      show1: false,
      password: "",
      email: "",
      rules: {
        required: (value) => !!value || this.$t('login.thisFieldIsRequired'),
        emailRules: [
          (v) => !!v || this.$t("login.thisFieldIsRequired"),
          (v) => /.+@.+\..+/.test(v) || this.$t("login.emailMustBeValid"),
        ],
      },
    };
  },
  methods: {
    onLogin: function() {
      if (this.$refs.form.validate()) {
        // let entityId = sessionStorage.getItem("entityId");
        let filter = {
          username: this.email,
          password: this.password,
          // entityId: entityId,
        };
        this.REPORT_LOGIN_STATISTIC(filter)
          .then(
            function(res) {
              this.$toast.open({
                message: this.$t('common.success'),
                type: "success",
                ...this.$toastConfig,
              });
              let token = res.data.response.token;
              let userId = res.data.response.id;
              if (isCookieEnabled()) {
                setCookie("report_statistic_token", token);
                setCookie("userReportId", userId);
              }
              this.$router.push({
                name: "ReportStatisticView",
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              if (isCookieEnabled()) {
                removeCookie("report_statistic_token");
                removeCookie("userReportId");
              }
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText;
                this.$toast.open({
                  message: this.$t(errorText),
                  type: "error",
                  ...this.$toastConfig,
                });
              } else {
                this.$toast.open({
                  message: this.$t('common.somethingWentWrong'),
                  type: "error",
                  ...this.$toastConfig,
                });
              }
            }.bind(this)
          );
      }
    },
    ...mapActions(["REPORT_LOGIN_STATISTIC"]),
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
